// Aqui se centralizan los strings de las rutas al API
export class API {
    public static webApiBase = process.env.VUE_APP_ROOT_API;
    public static webApiBaseImages = process.env.VUE_APP_ROOT_PATH+'Images/';
    public static webApiBaseinventario = process.env.VUE_APP_ROOT_PATH+'inventario/';
    public static webApiBaseactuaciones = process.env.VUE_APP_ROOT_PATH+'actuaciones/';
    public static webApiBaseRepositoriArchivos = process.env.VUE_APP_ROOT_PATH+'repositorio_archivos/';
    public static webApiBaseRootPath = process.env.VUE_APP_ROOT_PATH;//'/'
    
    public static login = API.webApiBase + 'Login';
    public static usuarios = API.webApiBase + 'Usuarios';
    public static roles = API.webApiBase + 'Roles';
    public static VersionBack = API.webApiBase + 'VersionBack';
    /*************************** */
    public static departamentos = API.webApiBase + 'departamentos';
    public static empresas = API.webApiBase + 'empresas';
    public static SendingEmails = API.webApiBase + 'SendingEmails';
    public static servidor_smtp = API.webApiBase + 'servidor_smtp';
    public static usuario = API.webApiBase + 'usuarios';
    public static pantalla_lista = API.webApiBase + 'pantalla_lista';
    public static enumeracion = API.webApiBase + 'enumeraciones';
    public static grupo_usuario = API.webApiBase + 'grupo_usuarios';
    public static Connect = API.webApiBase + 'Connect';
    public static cambioscontrasenasperdidas = API.webApiBase + 'cambioscontrasenasperdidas';
    public static categoria_invetario = API.webApiBase + 'categoria_invetario';
    public static invetario = API.webApiBase + 'inventario';
    public static rol = API.webApiBase + 'roles';
    public static mantenimiento_permiso = API.webApiBase + 'mantenimiento_permisos';
    public static permiso = API.webApiBase + 'permiso';
    public static TestAvatar = API.webApiBase + 'TestAvatar';
    public static menu_option = API.webApiBase + 'menu_options';
    public static ambito = API.webApiBase + 'ambitos';
    public static plan = API.webApiBase + 'planes';
    public static contador = API.webApiBase + 'contadores';
    public static objetivo = API.webApiBase + 'objetivos';
    public static actuacion = API.webApiBase + 'actuaciones';
    public static tarea = API.webApiBase + 'tareas';
    public static notificaciones = API.webApiBase + 'notificaciones';
    public static DashBoard = API.webApiBase + 'DashBoard';
    public static ticket = API.webApiBase + 'tickets';
    public static cliente = API.webApiBase + 'clientes';
    public static kpis_actuacion = API.webApiBase + 'kpis_actuacion';
    public static mediciones_kpi = API.webApiBase + 'mediciones_kpis';
    public static RepositorioInformes = API.webApiBase + 'RepositorioInformes';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';



}
